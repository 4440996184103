import '@angular/compiler';
import { PortalDizestSidebarWorkflowinfoComponent } from './portal.dizest.sidebar.workflowinfo/portal.dizest.sidebar.workflowinfo.component';
import { PortalDizestEditorUimodeComponent } from './portal.dizest.editor.uimode/portal.dizest.editor.uimode.component';
import { PortalDizestSidebarTimerComponent } from './portal.dizest.sidebar.timer/portal.dizest.sidebar.timer.component';
import { PortalDizestEditorImageComponent } from './portal.dizest.editor.image/portal.dizest.editor.image.component';
import { PageManageDatasetListComponent } from './page.manage.dataset.list/page.manage.dataset.list.component';
import { PageWorkflowComponent } from './page.workflow/page.workflow.component';
import { PortalSeasonViewerTreeComponent } from './portal.season.viewer.tree/portal.season.viewer.tree.component';
import { PageManageDashboardListComponent } from './page.manage.dashboard.list/page.manage.dashboard.list.component';
import { ComponentManageDatasetDriveComponent } from './component.manage.dataset.drive/component.manage.dataset.drive.component';
import { PageManageDatasetShareViewComponent } from './page.manage.dataset.share.view/page.manage.dataset.share.view.component';
import { PortalDizestEditorSettingComponent } from './portal.dizest.editor.setting/portal.dizest.editor.setting.component';
import { ComponentCardNotificationComponent } from './component.card.notification/component.card.notification.component';
import { PortalDizestStatuscardComponent } from './portal.dizest.statuscard/portal.dizest.statuscard.component';
import { LayoutEmptyComponent } from './layout.empty/layout.empty.component';
import { PortalDizestSidebarPackagesComponent } from './portal.dizest.sidebar.packages/portal.dizest.sidebar.packages.component';
import { PageAuthenticateComponent } from './page.authenticate/page.authenticate.component';
import { ComponentPagenationComponent } from './component.pagenation/component.pagenation.component';
import { PortalDizestCodeflowAppinfoComponent } from './portal.dizest.codeflow.appinfo/portal.dizest.codeflow.appinfo.component';
import { PageManageDatasetShareManageComponent } from './page.manage.dataset.share.manage/page.manage.dataset.share.manage.component';
import { PortalSeasonPagenationComponent } from './portal.season.pagenation/portal.season.pagenation.component';
import { ComponentNavigationSubComponent } from './component.navigation.sub/component.navigation.sub.component';
import { PortalDizestEditorWorkflowComponent } from './portal.dizest.editor.workflow/portal.dizest.editor.workflow.component';
import { PortalDizestCodeflowNoteComponent } from './portal.dizest.codeflow.note/portal.dizest.codeflow.note.component';
import { LayoutWorkflowComponent } from './layout.workflow/layout.workflow.component';
import { ComponentNavigationComponent } from './component.navigation/component.navigation.component';
import { PortalDizestDriveComponent } from './portal.dizest.drive/portal.dizest.drive.component';
import { PortalDizestWidgetWorkflowNodeComponent } from './portal.dizest.widget.workflow.node/portal.dizest.widget.workflow.node.component';
import { PortalDizestEditorNotsupportedComponent } from './portal.dizest.editor.notsupported/portal.dizest.editor.notsupported.component';
import { PortalSeasonUiDropdownComponent } from './portal.season.ui.dropdown/portal.season.ui.dropdown.component';
import { PortalDizestSidebarCodeflowComponent } from './portal.dizest.sidebar.codeflow/portal.dizest.sidebar.codeflow.component';
import { PageMainComponent } from './page.main/page.main.component';
import { ComponentLoadingHexaComponent } from './component.loading.hexa/component.loading.hexa.component';
import { PortalDizestAppsComponent } from './portal.dizest.apps/portal.dizest.apps.component';
import { PageManageDatasetEditComponent } from './page.manage.dataset.edit/page.manage.dataset.edit.component';
import { ComponentLoadingComponent } from './component.loading/component.loading.component';
import { PortalSeasonLoadingDefaultComponent } from './portal.season.loading.default/portal.season.loading.default.component';
import { LayoutNavbarComponent } from './layout.navbar/layout.navbar.component';
import { PortalDizestEditorTerminalComponent } from './portal.dizest.editor.terminal/portal.dizest.editor.terminal.component';
import { PortalDizestEditorVideoComponent } from './portal.dizest.editor.video/portal.dizest.editor.video.component';
import { LayoutTopnavComponent } from './layout.topnav/layout.topnav.component';
import { PortalSeasonStatusbarComponent } from './portal.season.statusbar/portal.season.statusbar.component';
import { PortalSeasonTabComponent } from './portal.season.tab/portal.season.tab.component';
import { PortalSeasonLoadingHexaComponent } from './portal.season.loading.hexa/portal.season.loading.hexa.component';
import { PageManageMacroListComponent } from './page.manage.macro.list/page.manage.macro.list.component';
import { PageManageMacroEditComponent } from './page.manage.macro.edit/page.manage.macro.edit.component';
import { PortalSeasonLoadingSeasonComponent } from './portal.season.loading.season/portal.season.loading.season.component';
import { PageManageDashboardEditComponent } from './page.manage.dashboard.edit/page.manage.dashboard.edit.component';
import { PortalDizestEditorTextComponent } from './portal.dizest.editor.text/portal.dizest.editor.text.component';
import { PortalSeasonAlertComponent } from './portal.season.alert/portal.season.alert.component';
import { PortalDizestHealthComponent } from './portal.dizest.health/portal.dizest.health.component';
import { PageMypageComponent } from './page.mypage/page.mypage.component';
import { PageManageDatasetShareComponent } from './page.manage.dataset.share/page.manage.dataset.share.component';
import { PageManageUserComponent } from './page.manage.user/page.manage.user.component';
import { MatTreeModule } from '@angular/material/tree';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, COMPOSITION_BUFFER_MODE } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NuMonacoEditorModule } from '@ng-util/monaco-editor';
import { SortablejsModule } from "src/libs/portal/season/ngx-sortablejs";
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';

@NgModule({
    declarations: [
        AppComponent,
        PortalDizestSidebarWorkflowinfoComponent,
        PortalDizestEditorUimodeComponent,
        PortalDizestSidebarTimerComponent,
        PortalDizestEditorImageComponent,
        PageManageDatasetListComponent,
        PageWorkflowComponent,
        PortalSeasonViewerTreeComponent,
        PageManageDashboardListComponent,
        ComponentManageDatasetDriveComponent,
        PageManageDatasetShareViewComponent,
        PortalDizestEditorSettingComponent,
        ComponentCardNotificationComponent,
        PortalDizestStatuscardComponent,
        LayoutEmptyComponent,
        PortalDizestSidebarPackagesComponent,
        PageAuthenticateComponent,
        ComponentPagenationComponent,
        PortalDizestCodeflowAppinfoComponent,
        PageManageDatasetShareManageComponent,
        PortalSeasonPagenationComponent,
        ComponentNavigationSubComponent,
        PortalDizestEditorWorkflowComponent,
        PortalDizestCodeflowNoteComponent,
        LayoutWorkflowComponent,
        ComponentNavigationComponent,
        PortalDizestDriveComponent,
        PortalDizestWidgetWorkflowNodeComponent,
        PortalDizestEditorNotsupportedComponent,
        PortalSeasonUiDropdownComponent,
        PortalDizestSidebarCodeflowComponent,
        PageMainComponent,
        ComponentLoadingHexaComponent,
        PortalDizestAppsComponent,
        PageManageDatasetEditComponent,
        ComponentLoadingComponent,
        PortalSeasonLoadingDefaultComponent,
        LayoutNavbarComponent,
        PortalDizestEditorTerminalComponent,
        PortalDizestEditorVideoComponent,
        LayoutTopnavComponent,
        PortalSeasonStatusbarComponent,
        PortalSeasonTabComponent,
        PortalSeasonLoadingHexaComponent,
        PageManageMacroListComponent,
        PageManageMacroEditComponent,
        PortalSeasonLoadingSeasonComponent,
        PageManageDashboardEditComponent,
        PortalDizestEditorTextComponent,
        PortalSeasonAlertComponent,
        PortalDizestHealthComponent,
        PageMypageComponent,
        PageManageDatasetShareComponent,
        PageManageUserComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        NgbModule,
        SortablejsModule,
        KeyboardShortcutsModule.forRoot(),
        NuMonacoEditorModule.forRoot({ baseUrl: `lib` }),
                MatTreeModule
    ],
    providers: [
        {
            provide: COMPOSITION_BUFFER_MODE,
            useValue: false
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }