import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.mypage');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-mypage',
template: templateSource || '',
    styles: [`

/* file: /var/www/kmbig_admin/project/main/build/src/app/page.mypage/view.scss */
.content-page-header {
  display: flex;
  align-items: center;
  padding: 18px 16px;
  padding-top: 0;
}
.content-page-header * {
  margin: 0;
  line-height: 1;
}

.card-body {
  border: none;
}

.alert {
  font-size: 16px;
}

.wiz-form .wiz-form-rows:first-child {
  border-top: none;
}

.wiz-form .wiz-form-rows:last-child {
  border-bottom: none;
}

.btn-save {
  background: #6758F0;
  color: white;
  border-radius: 16px;
}

.btn-edit {
  background: #6758F0;
  color: white;
  border-radius: 16px;
}

.card {
  border-radius: 16px;
}
.card .card-footer {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}`],
})
export class PageMypageComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public data: any = {};

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/auth/login");
        await this.load();
    }

    public async alert(message: string, status: any = "error") {
        return await this.service.alert.show({
            title: '',
            message: message,
            cancel: false,
            actionBtn: status,
            action: "확인",
            status: status
        });
    }

    public async load() {
        const { data } = await wiz.call("session");

        this.data.user = data;
        this.data.password = {}

        await this.service.render();
    }

    public async update() {
        let userinfo = JSON.stringify(this.data.user);
        const { code, data } = await wiz.call("update", { userinfo });
        if (code == 200) {
            await this.alert("저장되었습니다", 'success');
            // await this.load();
            location.reload();
            return;
        }

        await this.alert("오류가 발생했습니다");
    }

    public async changePassword() {
        let pdata = JSON.parse(JSON.stringify(this.data.password));

        if (!pdata.current) {
            await this.alert("현재 비밀번호를 입력해주세요");
            return;
        }
        if (!pdata.data || !pdata.repeat) {
            await this.alert("변경 비밀번호를 입력해주세요");
            return;
        }

        if (pdata.data != pdata.repeat) {
            await this.alert("변경 비밀번호를 다시 확인해주세요");
            return;
        }

        let pd = {};
        pd.current = this.service.auth.hash(pdata.current);
        pd.data = this.service.auth.hash(pdata.data);

        const { code, data } = await wiz.call("change_password", pd);

        if (code != 200) {
            await this.alert(data);
            return;
        }

        location.href = "/auth/logout";
    }

}

export default PageMypageComponent;