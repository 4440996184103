import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('layout.topnav');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { Router } from '@angular/router';

@Component({
    selector: 'wiz-layout-topnav',
template: templateSource || '',
    styles: [`

/* file: /var/www/kmbig_admin/project/main/build/src/app/layout.topnav/view.scss */
.wiz-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
  align-items: center;
}

.wiz-navbar {
  width: 100%;
  background: white;
}

.container-mainmenu {
  border-bottom: 1px solid #D2D4DA;
}

.cotainer-submenu {
  background: white;
  border-bottom: 1px solid #D2D4DA;
}

.wiz-content {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.hidden {
  display: none;
}

.block-page {
  display: flex;
  align-items: center;
  justify-content: center;
}`],
})
export class LayoutTopnavComponent implements OnInit {
    constructor(@Inject( Service)         public service: Service,@Inject( Router    )         public router: Router    ) { }

    public length: any;
    public async ngOnInit() {
        await this.service.init();
        this.currentRoute = this.router.url;
    }
}

export default LayoutTopnavComponent;