import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.manage.dashboard.edit');
import { OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Service } from "src/libs/portal/season/service";
import { KeyMod, KeyCode } from 'monaco-editor';

@Component({
    selector: 'wiz-page-manage-dashboard-edit',
template: templateSource || '',
    styles: [`

/* file: /var/www/kmbig_admin/project/main/build/src/app/page.manage.dashboard.edit/view.scss */
.wiz-content-page {
  flex-direction: row;
  margin: auto;
}
.wiz-content-page .eform-content {
  flex: 1;
  overflow: auto;
  display: flex;
}
.wiz-content-page .eform-content .eform-editor {
  min-width: 860px;
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  opacity: 1;
}
.wiz-content-page .eform-content .eform-editor:last-child {
  border-right: none;
}
.wiz-content-page .eform-content .eform-editor.active {
  opacity: 1;
}
.wiz-content-page .eform-content .eform-editor .eform-editor-header {
  font-size: 20px;
  font-family: "main-eb";
  line-height: 1;
  display: flex;
  background-color: #B9B9DD;
  color: #fff;
}
.wiz-content-page .eform-content .eform-editor .eform-editor-header .eform-editor-title {
  padding: 4px 8px;
}
.wiz-content-page .eform-content .eform-editor .eform-editor-header .eform-editor-btn {
  padding: 4px 8px;
  cursor: pointer;
}
.wiz-content-page .eform-content .eform-editor .eform-editor-header .eform-editor-btn:hover {
  background-color: #000;
}
.wiz-content-page .eform-content .eform-editor .eform-editor-subheader {
  font-family: "main-eb";
  display: flex;
  background-color: #B9B9DD;
  border-bottom: 1px solid var(--wc-border);
  line-height: 1;
}
.wiz-content-page .eform-content .eform-editor .eform-editor-subheader .eform-editor-tab {
  cursor: pointer;
  color: #fff;
  padding: 5px 12px;
  padding-top: 6px;
  line-height: 1;
  font-size: 12px;
}
.wiz-content-page .eform-content .eform-editor .eform-editor-subheader .eform-editor-tab.active {
  background-color: #fff;
  color: #B9B9DD;
}
.wiz-content-page .eform-content .eform-editor .eform-editor-subheader .eform-editor-tab:hover {
  background-color: #000;
}
.wiz-content-page .eform-content .eform-editor .eform-editor-body {
  flex: 1;
  overflow: auto;
  position: relative;
}
.wiz-content-page .eform-content .eform-editor .eform-editor-body .editor-submenu {
  position: absolute;
  bottom: 16px;
  left: 0;
  width: 100%;
  text-align: center;
}

.editor-monaco {
  position: relative;
  width: 100%;
  height: 956px;
}

.btn-border {
  border-radius: 10px;
  border: 1px solid white;
}

.box-shadow {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}

.btn-save {
  background: #6758F0;
  color: white;
  border-radius: 10px;
  border: 1px solid #6758F0;
}

.board-table {
  background: white;
}
.board-table td,
.board-table th {
  white-space: nowrap;
  text-align: left;
}
.board-table .width-1 {
  width: 80px;
}
.board-table .width-2 {
  width: 120px;
}
.board-table .width-3 {
  width: 180px;
}
.board-table .width-4 {
  width: 240px;
}
.board-table .width-5 {
  width: 300px;
}
.board-table thead th {
  background-color: #B9B9DD;
  color: black;
  font-size: 16px;
  font-weight: bold;
  padding: 12px 0;
}

.br-10 {
  border-radius: 10px;
}

.editor-preview {
  height: 100%;
  width: 100%;
}

iframe {
  width: 100%;
  height: 1000px;
}

.loading-wrap {
  display: flex;
  position: absolute;
  inset: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.08);
}

.input-disabled {
  background: #F1F5F9;
}`],
})
export class PageManageDashboardEditComponent implements OnInit {
    constructor(@Inject( Service) public service: Service,@Inject( DomSanitizer)  private sanitizer: DomSanitizer) { }

    public shortcuts: any = [];
    public src: any;
    public view: any;
    public current: any;
    public item: any = {
        namespace: "",
        short: "",
        category: "",
        title: "",
        status: "edit",
        trigger: {},
        extra: {}
    };

    public id: any;
    public files: any = [];
    public info = {
        id: "",
        category: "",
        content: "",
        period: "",
        filepath: "",
        down_period: "",
        drive_period: ""
    };
    public category_ls = {
        "한의 건강검진": "device",
        "혈자리": "blood",
        "한약재": "medicine",
        "의료기관": "medical",
        "탕약": "decoction"
    };

    // public wiz: any;
    public wiz = {
        pug: "",
        component: "",
        scss: "",
        api: ""
    };
    public preview = null;

    public gub: any;

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, '/authenticate');

        this.shortcuts = [
            {
                name: "save",
                key: ["cmd + s", "ctrl + s"],
                monaco: KeyMod.CtrlCmd | KeyCode.KeyS,
                preventDefault: true,
                command: async () => {
                    // if (this.activeTab)
                    //     await this.save(this.activeTab);
                    await this.save();
                }
            },
            {
                name: "close",
                key: ["alt + w"],
                monaco: KeyMod.Alt | KeyCode.KeyW,
                preventDefault: true,
                command: async () => {
                    if (this.activeTab)
                        this.activeTab.close();
                }
            }
        ]
        await this.load();
        await this.service.render();
    }

    public async alert(message: string, status: string = 'error', cancel: any = false, action: string = '확인') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public async load() {
        this.src = 'info';
        this.view = 'info';
        this.id = WizRoute.segment.category;

        let { code, data } = await wiz.call("load", { "id": this.id })
        if (code == 200) {
            this.info = data.info_row;
            // this.gub = this.info.namespace.substring(17);
            this.gub = this.info.namespace;


            // if (this.info.code != null) this.wiz = JSON.parse(this.info.code);
            this.wiz = this.info.code;
            if (typeof (this.wiz) == 'string') this.wiz = JSON.parse(this.wiz);
            this.files = data.file_row;
            this.list = data.dataset_row;
            for (let i = 0; i < this.list.length; i++) {
                this.list[i].created = this.list[i].created.substr(0, 10);
                this.list[i].updated = this.list[i].updated.substr(0, 10);

                this.list[i].tags = JSON.parse(this.list[i].tags);
                for (let j = 0; j < this.list[i].tags.length; j++) {
                    if (this.list[i].tags[j].length > 5) {
                        this.list[i].tags[j] = this.list[i].tags[j].substr(0, 5) + '...';
                    }
                }
            }

            this.setting.download = data.info_row.down_period;
            this.setting.drive = data.info_row.drive_period;
            

            await this.service.render();
        }
        await this.service.render();
    }

    public safeUrl(url: string) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    public getUIBinding(namespace: string) {
        if (namespace) {
            return namespace.replace(/\./gim, '-')
        }
        return ""
    }

    public async save() {
        this.info.code = JSON.stringify(this.wiz);
        this.info.namespace = this.gub;
        this.info.down_period = this.setting.download;
        this.info.drive_period = this.setting.drive;
        console.log("namespace:", this.info.namespace)
        let copy = Object.assign({}, this.info)
        console.log(copy)
        const { code, data } = await wiz.call("save", copy);
        await this.service.render();

        if (code == 201) {
            await this.alert(data, "error");
            return;
        }

        if (this.src == 'code') {
            await this.loading(true);
            await this.service.request.post("https://kmbig.seasonsoft.net/wiz/ide/api/workspace.app.explore/build");
            await this.loading(false);
            return this.service.toast.success("저장되었습니다.");
        } else {
            let res = await this.alert("저장되었습니다.", 'success');
            if (res) {
                if (WizRoute.segment.category == 'new') {
                    let extracted = this.info.namespace.match(/portal.dashboard\.(.*)/)?.[1];
                    await this.service.href(`/dashboard/edit/${extracted}`);
                }
            }
        }
        await this.service.render();
    }

    public async remove() {
        console.log("remove:", this.info.category)
        console.log("remove2:", this.info.namespace)

        let res = await this.alert("대시보드를 삭제하시겠습니까?", "error", "취소");
        if (res) {
            let { code } = await wiz.call("remove", { category: this.info.namespace })
            if (code != 200) return;
            await this.service.href(`/dashboard/list`)
            return;
        }
        await this.service.render();
    }

    public async delete(id, filepath) {
        let { code } = await wiz.call("delete", { 'id': id, 'filepath': filepath })
        if (code == 200) {
            await this.alert("파일이 삭제되었습니다.", 'error');
        }
        await this.load();
    }

    public async upload() {
        let files = await this.service.file.select({ accept: '.xls,.xlsx,.csv', multiple: true });

        let fd = new FormData();
        let filepath = [];
        for (let i = 0; i < files.length; i++) {
            if (!files[i].filepath) files[i].filepath = this.category_ls[this.info.category] + "/" + files[i].name;
            fd.append('file[]', files[i], files[i].name);
            filepath.push(files[i].filepath);
        }
        fd.append("data", JSON.stringify({ data: this.info }));
        fd.append("filepath", JSON.stringify(filepath));

        let url = wiz.url('upload');
        await this.service.file.upload(url, fd);
        await this.service.render();
        await this.load();
    }

    public async close() {
        if (WizRoute.segment.category == "new") {
            await this.alert("Namespace를 변경해주세요.", "error");
            return;
        }
        await this.service.href(`/dashboard/list`);
    }

    public async editorInit(e) {
        for (let i = 0; i < this.shortcuts.length; i++) {
            let shortcut = this.shortcuts[i];
            e.editor.addCommand(shortcut.monaco, shortcut.command);
        }
    }

    public async select(tab) {
        this.src = tab;

        if (this.src == 'info') {
            await this.setView("info");
            this.preview = null;
        } else if (this.src == 'dataset') {
            await this.setView("datasaet");
            this.preview = null;
        } else if (this.src == 'code') {
            await this.setView("pug");
            this.preview = null;
        } else if (this.src == 'preview') {
            await this.setView("preview");
            console.log('https://kmbig.seasonsoft.net/dashboard/' + this.category_ls[this.info.title])
            this.preview = this.safeUrl('https://kmbig-v4.seasonsoft.net/dashboard/' + this.category_ls[this.info.title]);
        }

        await this.service.render();
    }

    public async setView(view) {
        this.view = view;
        let monacoopt: any = (language: string) => {
            return {
                language: language,
                renderValidationDecorations: language == 'typescript' ? 'off' : 'on',
                theme: "vs",
                fontSize: 14,
                automaticLayout: true,
                scrollBeyondLastLine: true,
                wordWrap: true,
                roundedSelection: false,
                glyphMargin: false,
                minimap: {
                    enabled: false
                }
            }
        }

        if (view == 'pug') {
            this.current = {
                monaco: monacoopt('pug'),
                data: this.wiz,
                target: 'pug'
            }
        } else if (view == 'component') {
            this.current = {
                monaco: monacoopt('typescript'),
                data: this.wiz,
                target: 'component'
            }
        } else if (view == 'scss') {
            this.current = {
                monaco: monacoopt('scss'),
                data: this.wiz,
                target: 'scss'
            }
        } else if (view == 'api') {
            this.current = {
                monaco: monacoopt('python'),
                data: this.wiz,
                target: 'api'
            }
        }

        await this.service.render();
    }

    public setPeriod = [
        { down: "하루", drive: "한달" },
        { down: "일주일", drive: "3개월" },
        { down: "한달(30일)", drive: "6개월" },
        { down: "6개월", drive: "1년" },
        { down: "1년", drive: "2년" },
    ]

    public setting = { download: '', drive: '' };


    public async downChange(downPeriod) {
        this.setting.download = downPeriod;
    }

    public async driveChange(drivePeriod) {
        this.setting.drive = drivePeriod;
    }


    public click(id) {
        this.service.href(`/dataset/edit/${id}`);
    }

    // 데이터셋 추가
    public async add() {
        await this.service.href(`/dataset/edit/new`);
    }

    private async loading(act) {
        this._loading = act;
        await this.service.render();
    }
}

export default PageManageDashboardEditComponent;