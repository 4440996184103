import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.dizest.editor.uimode');
import { OnInit, OnDestroy } from '@angular/core';
import { Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
    selector: 'wiz-portal-dizest-editor-uimode',
template: templateSource || '',
    styles: [`

/* file: /var/www/kmbig_admin/project/main/build/src/app/portal.dizest.editor.uimode/view.scss */
.editor-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
}

.editor-menu {
  width: 100%;
  height: 24px;
  display: flex;
  border-bottom: 1px solid var(--wc-border);
  background-color: var(--wc-blue-light);
  align-items: center;
}
.editor-menu .editor-item {
  min-width: 60px;
  display: flex;
  height: 100%;
  padding: 0 8px;
  align-items: center;
  justify-content: center;
  border-left: 1px solid #fff;
  cursor: pointer;
}
.editor-menu .editor-item:hover, .editor-menu .editor-item.active {
  color: #fff;
  background-color: var(--wc-blue);
}
.editor-menu .editor-title {
  display: flex;
  height: 100%;
  padding: 0 8px;
  align-items: center;
}
.editor-menu .editor-action {
  min-width: 48px;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  border-left: 1px solid #fff;
  cursor: pointer;
  color: #fff;
  background-color: var(--wc-blue);
}
.editor-menu .editor-action:hover, .editor-menu .editor-action.active {
  color: #fff;
  background-color: var(--wc-blue);
}

.context {
  width: 100%;
  height: 100%;
  flex: 1;
  overflow: hidden;
}

iframe {
  width: 100%;
  height: 100%;
}`],
})
export class PortalDizestEditorUimodeComponent implements OnInit, OnDestroy {
    @Input() tab: any = null;

    public menu: any = 'viewer';
    public monaco: any = null;
    public url: any = null;
    public loading: any = null;

    constructor(@Inject( Service)         public service: Service,@Inject( DomSanitizer    )         public sanitizer: DomSanitizer    ) { }

    public async ngOnInit() {
        await this.service.init();
        this.menu = "viewer";
        await this.viewer();
        await this.service.render();
    }

    public async ngOnDestroy() {
    }

    public async switchMenu(menu: string) {
        if (this.menu == menu) return;

        if (menu != 'viewer') {
            this.monaco = null;
            await this.service.render();

            let languageMap: any = { html: 'html', js: 'javascript', css: 'scss', api: 'python' };

            this.monaco = {
                wordWrap: false,
                roundedSelection: false,
                scrollBeyondLastLine: true,
                glyphMargin: false,
                folding: true,
                fontSize: 14,
                automaticLayout: true,
                minimap: { enabled: false },
                language: languageMap[menu]
            };
        }

        this.menu = menu;
        await this.service.render();
    }

    public getUrl() {
        return this.tab.dizest.api.url("ui", `${this.tab.workflow.data.spawner_id}/${this.tab.flow.id()}/render`);
    }

    public async viewer() {
        this.url = null;
        await this.service.render();
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.getUrl());
        await this.service.render();
    }

    public async open() {
        window.open(this.getUrl(), '_blank');
    }

    public async update() {
        await this.tab.workflow.update();
    }

    public async onLoad() {
        this.loading = false;
        await this.service.render();
    }

}

export default PortalDizestEditorUimodeComponent;