import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.dizest.apps');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { Dizest } from "src/libs/portal/dizest/dizest";

@Component({
    selector: 'wiz-portal-dizest-apps',
template: templateSource || '',
    styles: [`

/* file: /var/www/kmbig_admin/project/main/build/src/app/portal.dizest.apps/view.scss */
.app {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}
.app .app-search {
  display: flex;
  padding: 14px 8px;
  border-bottom: 1px solid var(--wc-border);
  height: 50px;
  background: var(--wc-blue-light);
}
.app .app-search .btn,
.app .app-search .form-control {
  height: 28px;
}
.app .app-list {
  flex: auto;
  height: 100%;
  overflow-y: auto;
  padding-bottom: 120px;
}
.app .app-list .no-items {
  font-size: 18px;
  font-weight: 1000;
  color: #555;
}
.app .app-list .no-items .btn.btn-primary {
  border-radius: 32px;
}
.app .app-list .no-items i {
  font-size: 48px;
  color: var(--wc-blue);
}
.app .app-list .app-category {
  background: var(--wc-light);
  color: var(--wc-text);
  padding: 4px 12px;
  border-bottom: 1px solid var(--wc-border);
  font-size: 14px;
  line-height: 1.2;
}
.app .app-list .app-info {
  border-bottom: 1px solid var(--wc-border);
  line-height: 1;
  padding: 8px 16px;
  cursor: grab;
  display: flex;
  align-items: center;
}
.app .app-list .app-info :active {
  cursor: grabbing;
}
.app .app-list .app-info.active {
  background: var(--wc-blue-light);
}
.app .app-list .app-info .title-area {
  flex: auto;
}
.app .app-list .app-info .title-area .app-header {
  font-size: 14px;
  font-weight: 1000;
  width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: none;
  background: transparent;
}
.app .app-list .app-info .title-area .app-namespace {
  font-size: 12px;
}
.app .app-list .app-info:hover {
  background: var(--wc-blue-light);
}
.app .app-list .app-info .btn {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}
.app .app-list .app-info .btn i {
  font-size: 12px;
  width: 12px;
  height: 12px;
}`],
})
export class PortalDizestAppsComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) {
        this.alert = this.service.alert.localize({
            title: "Are you sure?",
            cancel: 'Cancel',
            message: "Do you really want to remove app? What you've done cannot be undone."
        });
    }

    @Input() dizest: Dizest;
    @Input() config: any = {};

    public alert: any;
    public keyword: string = '';

    public async ngOnInit() {
        await this.service.init();
    }

    public workflow() {
        try {
            this.config.tab.selected.workflow.data.apps.length;
        } catch (e) {
            return null;
        }
        if (this.config.tab.selected.workflow)
            return this.config.tab.selected.workflow;
        return null;
    }

    public categories() {
        let apps = this.workflow().data.apps;
        let categories = [];
        for (let app_id in apps) {
            let category = apps[app_id].category;
            if (!category) category = 'undefined';
            if (categories.indexOf(category) == -1)
                categories.push(category);
        }
        return categories;
    }

    public appList(category: any = false) {
        let data = this.workflow().data;
        let apps = [];
        if (category) {
            for (let app_id in data.apps) {
                if (category == 'undefined')
                    if (!data.apps[app_id].category || data.apps[app_id].category == '')
                        apps.push(data.apps[app_id]);
                if (data.apps[app_id].category == category)
                    apps.push(data.apps[app_id]);
            }

        } else {
            for (let app_id in data.apps) {
                apps.push(data.apps[app_id]);
            }
        }

        apps.sort((a, b) => {
            return a.title.localeCompare(b.title);
        });

        return apps;
    }

    public match(item: any, keyword: string) {
        if (item.title.toLowerCase().indexOf(keyword.toLowerCase()) > -1)
            return true;
        return false;
    }

    public async search(keyword: any = null) {
        if (keyword !== null) this.keyword = keyword;
        await this.service.render();
    }

    public async create() {
        if (this.keyword) {
            await this.workflow().app.create({ title: this.keyword });
        } else {
            await this.workflow().app.create();
        }
        await this.service.render();
    }

    public async import() {
        let dwas = await this.service.file.read({ type: 'json', accept: '.dwa', multiple: true });
        for (let i = 0; i < dwas.length; i++)
            await this.workflow().app.create(dwas[i]);
        await this.service.render();
    }

    public drop: any = () => {
        let scope = this;
        return async (event) => {
            for (let i = 0; i < event.dataTransfer.files.length; i++) {
                let reader = new FileReader();
                reader.onload = async (readerEvent) => {
                    try {
                        let dwa = JSON.parse(readerEvent.target.result);
                        await scope.workflow.app.create(dwa);
                        await scope.service.render();
                    } catch (e) {
                        scope.service.toast.error('Not supported file format');
                    }
                };
                reader.readAsText(event.dataTransfer.files[i]);
            }
        }
    }

    public async add(item: any) {
        await this.workflow().flow.create(item.id, {});
    }

    public async delete(item: any) {
        let res = await this.alert.show();
        if (!res) return;
        await this.workflow().app.delete(item.id);
    }

    public drag(event: any, item: any) {
        event.dataTransfer.setData("app-id", item.id);
    }

    public async hoverin(item: any) {
        await this.workflow().app.hover(item.id);
    }

    public async hoverout(item: any) {
        await this.workflow().app.hover();
    }

}

export default PortalDizestAppsComponent;