import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.manage.dataset.edit');
import { OnInit, ElementRef, ViewChild } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import ClassicEditor from "src/libs/ckeditor/ckeditor";

// code editor
import { DomSanitizer } from '@angular/platform-browser';
import { KeyMod, KeyCode } from 'monaco-editor';

@Component({
    selector: 'wiz-page-manage-dataset-edit',
template: templateSource || '',
    styles: [`

/* file: /var/www/kmbig_admin/project/main/build/src/app/page.manage.dataset.edit/view.scss */
.container {
  overflow: auto;
}

.btn-border {
  border-radius: 10px;
  border: 1px solid white;
}

.box-shadow {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}

.btn-save {
  background: #6758F0;
  color: white;
  border-radius: 10px;
  border: 1px solid #6758F0;
}

.btn-remove {
  background: red;
  color: white;
  border-radius: 10px;
  border: 1px solid red;
}

.info-form {
  padding: 12px 0;
  border-bottom: 1px solid rgba(110, 117, 130, 0.2);
}
.info-form input {
  border-radius: 0;
}
.info-form h4 {
  margin-top: 9px;
}

.info-form.first-child {
  border-top: 2px solid #000000;
}

.loading-wrap {
  display: flex;
  position: absolute;
  inset: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.08);
}

.wiz-content-page {
  flex-direction: row;
  margin: auto;
}
.wiz-content-page .eform-content {
  flex: 1;
  overflow: auto;
  display: flex;
}
.wiz-content-page .eform-content .eform-editor {
  min-width: 860px;
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.wiz-content-page .eform-content .eform-editor:last-child {
  border-right: none;
}
.wiz-content-page .eform-content .eform-editor.active {
  opacity: 1;
}
.wiz-content-page .eform-content .eform-editor .test {
  position: sticky;
  top: 0;
  z-index: 100;
  width: 100%;
  height: auto;
}
.wiz-content-page .eform-content .eform-editor .eform-editor-header {
  font-size: 20px;
  font-family: "main-eb";
  line-height: 1;
  display: flex;
  background-color: #B9B9DD;
  color: #fff;
}
.wiz-content-page .eform-content .eform-editor .eform-editor-header .eform-editor-title {
  padding: 4px 8px;
}
.wiz-content-page .eform-content .eform-editor .eform-editor-header .eform-editor-btn {
  padding: 4px 8px;
  cursor: pointer;
}
.wiz-content-page .eform-content .eform-editor .eform-editor-header .eform-editor-btn:hover {
  background-color: #000;
}
.wiz-content-page .eform-content .eform-editor .eform-editor-subheader {
  font-family: "main-eb";
  display: flex;
  background-color: #B9B9DD;
  border-bottom: 1px solid var(--wc-border);
  line-height: 1;
}
.wiz-content-page .eform-content .eform-editor .eform-editor-subheader .eform-editor-tab {
  cursor: pointer;
  color: #fff;
  padding: 5px 12px;
  padding-top: 6px;
  line-height: 1;
  font-size: 12px;
}
.wiz-content-page .eform-content .eform-editor .eform-editor-subheader .eform-editor-tab.active {
  background-color: #fff;
  color: #B9B9DD;
}
.wiz-content-page .eform-content .eform-editor .eform-editor-subheader .eform-editor-tab:hover {
  background-color: #000;
}
.wiz-content-page .eform-content .eform-editor .eform-editor-body {
  flex: 1;
  overflow: auto;
  position: relative;
}
.wiz-content-page .eform-content .eform-editor .eform-editor-body .editor-submenu {
  position: absolute;
  bottom: 16px;
  left: 0;
  width: 100%;
  text-align: center;
}

.warn {
  margin: 3px 10px;
  color: red;
}

.editor-monaco {
  position: relative;
  width: 100%;
  height: 956px;
}

.btn-border {
  border-radius: 10px;
}

.board-table {
  background: white;
}
.board-table td,
.board-table th {
  white-space: nowrap;
  text-align: left;
}
.board-table .width-1 {
  width: 80px;
}
.board-table .width-2 {
  width: 120px;
}
.board-table .width-3 {
  width: 180px;
}
.board-table .width-4 {
  width: 240px;
}
.board-table .width-5 {
  width: 300px;
}
.board-table thead th {
  background-color: #B9B9DD;
  color: black;
  font-size: 16px;
  font-weight: bold;
  padding: 12px 0;
}

.br-10 {
  border-radius: 10px;
}

.editor-preview {
  height: 100%;
  width: 100%;
}

iframe {
  width: 100%;
  height: 1000px;
}

.container-drive .navigation {
  margin-top: 15px;
  padding: 8px 16px;
  background: #B0B0E8;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.container-drive table tr {
  background: white;
  border-style: solid;
}
.container-drive table tr td {
  padding: 10px 16px;
}
.container-drive table tr td .avatar-container {
  display: flex;
  align-items: center;
  height: 32px;
  width: 32px;
  border-radius: 4px;
  text-align: center;
  vertical-align: middle;
}
.container-drive table tr td .avatar-container.folder {
  background-color: #B0B0E8;
  color: #EDEEF8;
}
.container-drive table tr td .avatar-container.file {
  background-color: #EDEEF8;
  color: #B0B0E8;
}
.container-drive table tr td .avatar-container i {
  width: 32px;
  align-items: center;
}`],
})
export class PageManageDatasetEditComponent implements OnInit {
    @ViewChild('editor')
    public editorElement: ElementRef;
    public editor: any;

    public id;
    public tag_input;
    public period_input;

    public files: any = [];
    public info = {
        id: "",
        title: "",
        datacode: "",
        category: "",
        filetype: "",
        filepath: "",
        period: "",
        schema: "",
        summary: "",
        content: "",
        tags: "",
        visibility: "",
        identification: "",
        department: "",
        datatype: "",
        main_manager_name: "",
        main_manager_email: "",
        sub_manager_name: "",
        sub_manager_email: "",
        created: "",
        updated: ""
    };
    public category_ls = {
        "한의 건강검진": "device",
        "한약재": "medicine",
        "연계": "multiple"
    };

    public count = 0;

    public Editor = ClassicEditor;

    public shortcuts: any = [];
    public src = "info";
    public view = "info";
    public current: any;
    public item = {
        namespace: "",
        short: "",
        category: "",
        title: "",
        status: "edit",
        trigger: {},
        extra: {}
    };
    public wiz = {
        pug: "",
        component: "",
        scss: "",
        api: ""
    };
    public preview = null;
    public namespaceUIBinding: any;

    constructor(@Inject( Service)         public service: Service,@Inject( DomSanitizer    )         private sanitizer: DomSanitizer    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, '/authenticate');
        await this.load();
        this.shortcuts = [
            {
                name: "saveCode",
                key: ["cmd + s", "ctrl + s"],
                monaco: KeyMod.CtrlCmd | KeyCode.KeyS,
                preventDefault: true,
                command: async () => {
                    // if (this.activeTab)
                    //     await this.save(this.activeTab);
                    await this.saveCode();
                }
            },
            {
                name: "close",
                key: ["alt + w"],
                monaco: KeyMod.Alt | KeyCode.KeyW,
                preventDefault: true,
                command: async () => {
                    if (this.activeTab)
                        this.activeTab.close();
                }
            }
        ]

        await this.buildEditor();
        await this.service.render();
    }

    public async alert(message: string, status: string = 'error', cancel: any = false, action: string = '확인') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public async load() {
        this.src = 'info';
        this.view = 'info';
        this.id = WizRoute.segment.id;
        let { code, data } = await wiz.call("load", { id: this.id, base: this.base });
        if (code !== 200) return;

        this.info = data.info_row;

        if (this.info.visibility == "") this.info.visibility = "none";
        if (this.info.category == "") this.info.category = "none";
        if (!this.info.period) this.info.period = [];
        else this.info.period = JSON.parse(this.info.period);
        this.info.tags = JSON.parse(this.info.tags);
        this.info.schema = JSON.parse(this.info.schema);

        this.wiz = data.code;
        if (typeof (this.wiz.code) == 'string') this.wiz.code = JSON.parse(this.wiz.code);

        this.namespaceUIBinding = "portal-dataset-" + this.getUIBinding(this.wiz.namespace);
        await this.service.render();
    }

    public safeUrl(url: string) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    public getUIBinding(namespace: string) {
        if (namespace) {
            return namespace.replace(/\./gim, '-')
        }
        return ""
    }

    public async numberOnly() {
        this.period_input = this.period_input.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
        await this.service.render();
    }

    public async remove() {
        let res = await this.alert("데이터셋을 삭제하시겠습니까?", "error", "취소");
        if (res) {
            let { code } = await wiz.call("remove", { id: this.info.id, category: this.info.category });
            if (code != 200) return;
            await this.service.href(`/dataset/list`);
            return;
        }
        await this.service.render();
    }
    public async save() {
        let noneList = { "visibility": "공개범위", "category": "카테고리", "filetype": "제공방식" };
        let noneKey = Object.keys(noneList);
        for (let none of noneKey) {
            if (this.info[none] == "none") {
                await this.alert(`${noneList[none]}을 선택해주세요.`, "error");
                return
            }
        }
        let copy = Object.assign({}, this.info)
        copy.period = JSON.stringify(copy.period);
        copy.tags = JSON.stringify(copy.tags);
        copy.schema = JSON.stringify(copy.schema);
        copy.content = this.editor.data.get();
        let { code } = await wiz.call("save", copy);

        if (code != 200) return;
        let res = await this.alert("저장되었습니다.", 'success');
        if (res) {
            if (WizRoute.segment.id == 'new') {
                await this.service.href(`/dataset/edit/${this.info.id}`);
            }
        }
        await this.service.render();
    }

    public async saveCode() {
        this.code = JSON.stringify(this.wiz.code);
        let { code } = await wiz.call("code", { "id": this.info.id, "title": this.info.title, "code": this.code, "namespace": this.wiz.namespace });
        if (code == 200) {
            await this.loading(true);
            await this.service.request.post("https://kmbig.seasonsoft.net/wiz/ide/api/workspace.app.portal/build");
            await this.loading(false);
            this.service.toast.success("저장되었습니다.");
        }
        await this.service.render();
    }

    public close() {
        this.service.href(`/dataset/list`);
    }

    public async editorInit(e) {
        for (let i = 0; i < this.shortcuts.length; i++) {
            let shortcut = this.shortcuts[i];
            e.editor.addCommand(shortcut.monaco, shortcut.command);
        }
    }

    public async select(tab) {
        this.src = tab;

        if (this.src == 'info') {
            await this.setView("info");
            this.preview = null;
            await this.buildEditor()
        } else if (this.src == 'drive') {
            await this.setView("drive");
            this.preview = null;
        } else if (this.src == 'code') {
            await this.setView("pug");
            this.preview = null;
        } else if (this.src == 'preview') {
            await this.setView("preview");
            this.preview = this.safeUrl('https://kmbig.seasonsoft.net/dashboard/' + this.category_ls[this.info.category] + "/" + this.info.id);
        }

        await this.service.render();
    }

    public async setView(view) {
        this.view = view;
        let monacoopt: any = (language: string) => {
            return {
                language: language,
                renderValidationDecorations: language == 'typescript' ? 'off' : 'on',
                theme: "vs",
                fontSize: 14,
                automaticLayout: true,
                scrollBeyondLastLine: true,
                wordWrap: true,
                roundedSelection: false,
                glyphMargin: false,
                minimap: {
                    enabled: false
                }
            }
        }

        if (view == 'pug') {
            this.current = {
                monaco: monacoopt('pug'),
                data: this.wiz,
                target: 'pug'
            }
        } else if (view == 'component') {
            this.current = {
                monaco: monacoopt('typescript'),
                data: this.wiz,
                target: 'component'
            }
        } else if (view == 'scss') {
            this.current = {
                monaco: monacoopt('scss'),
                data: this.wiz,
                target: 'scss'
            }
        } else if (view == 'api') {
            this.current = {
                monaco: monacoopt('python'),
                data: this.wiz,
                target: 'api'
            }
        }

        await this.service.render();
    }

    public async create_period(period) {
        if (!period || period.length == 0 || this.info.period.includes(period)) {
            return;
        }
        this.info.period.push(period);
        this.period_input = "";
        await this.service.render();
    }
    public async remove_period(period) {
        if (!period || period.length == 0) {
            return;
        }
        let index = this.info.period.indexOf(period);
        this.info.period.splice(index, 1);
        await this.service.render();
    }

    public async create_tag(tag) {
        if (!tag || tag.length == 0) {
            return;
        }
        this.info.tags.push(tag);
        this.tag_input = "";
        await this.service.render();
    }
    public async remove_tag(tag) {
        if (!tag || tag.length == 0) {
            return;
        }
        let index = this.info.tags.indexOf(tag);
        this.info.tags.splice(index, 1);
        await this.service.render();
    }

    public async buildEditor() {
        let editor = this.editor = await ClassicEditor.create(this.editorElement.nativeElement, {
            toolbar: {
                items: [
                    'undo', 'redo',
                    '|', 'heading',
                    '|', 'alignment:left', 'alignment:center', 'alignment:right', 'alignment:justify',
                    '|', 'fontColor', 'fontBackgroundColor', 'highlight',
                    '|', 'bold', 'italic', 'strikethrough', 'underline',
                    '|', 'bulletedList', 'numberedList', 'todoList', 'outdent', 'indent',
                    '|', 'link', 'uploadImage', 'insertTable', 'blockQuote', 'codeBlock', 'horizontalLine'
                ],
                shouldNotGroupWhenFull: true
            },
            heading: {
                options: [
                    { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                    { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                    { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                    { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                    { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                    { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                    { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' }
                ]
            },
            link: {
                addTargetToExternalLinks: true,
                defaultProtocol: 'https://',
            },
            removePlugins: ["MediaEmbedToolbar", "Markdown"],
            table: ClassicEditor.defaultConfig.table,
            simpleUpload: {
                // uploadUrl: '/files/dataset/upload/' + this.category_ls[this.info.category] + "/" + this.info.id
                uploadUrl: '/files/data/upload/dataset/' + this.info.id
            }
        });
        this.editor.data.set(this.info.content);
    }

    public async add_row() {
        if (this.info.schema == null) {
            this.info.schema = [];
        }
        let data = {
            name: "",
            type: "",
            desc: "",
            detail: ""
        }
        this.info.schema.push(data);
        await this.service.render();
    }

    public async del_row(index) {
        this.info.schema.splice(index, 1);
        await this.service.render();
    }

    private async loading(act) {
        this._loading = act;
        await this.service.render();
    }
}

export default PageManageDatasetEditComponent;