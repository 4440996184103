import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.main');
import { OnInit, Input } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-main',
template: templateSource || '',
    styles: [`

/* file: /var/www/kmbig_admin/project/main/build/src/app/page.main/view.scss */
.container-full {
  min-width: 1920px;
  height: 100%;
  overflow: auto;
}
.container-full .container-test .card {
  cursor: pointer;
  width: 400px;
  height: 500px;
  border-radius: 15px;
  margin: auto;
  margin-top: 100px;
}

.h1-red {
  color: red;
  font-weight: bold;
  text-align: center;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.h1-purple {
  color: purple;
  font-weight: bold;
  text-align: center;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.h1-black {
  color: black;
  font-weight: bold;
  text-align: center;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}`],
})
export class PageMainComponent implements OnInit {

    public share_ls = [
        { title: "미확인 공유 요청 건수", css: "h1-red", value: "request" },
        { title: "심사 진행 건수", css: "h1-purple", value: "process" },
        { title: "심사 완료 건수", css: "h1-black", value: "allow" },
    ]

    constructor(@Inject( Service)         public service: Service,@Inject( Router    )         private router: Router    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, '/authenticate');
        await this.load();
    }

    public async load() {
        let { code, data } = await wiz.call("load");
        this.role = data.role;

        this.share_ls[0].count = data.request;
        this.share_ls[1].count = data.process;
        this.share_ls[2].count = data.finish;

        await this.service.render();
    }

    public click(category) {
        console.log(category)
        this.service.href(`/share/list/` + category);

    }
}

export default PageMainComponent;