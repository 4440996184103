import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.manage.macro.list');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-manage-macro-list',
template: templateSource || '',
    styles: [`

/* file: /var/www/kmbig_admin/project/main/build/src/app/page.manage.macro.list/view.scss */
.container-full {
  width: 1780px;
  height: 100%;
  overflow: auto;
  margin: auto;
}
.container-full .container-sub {
  width: 100%;
  background: white;
  margin-top: 30px;
}

.board-table {
  background: white;
}
.board-table td,
.board-table th {
  white-space: nowrap;
  text-align: left;
}
.board-table .width-1 {
  width: 80px;
}
.board-table .width-2 {
  width: 120px;
}
.board-table .width-3 {
  width: 180px;
}
.board-table .width-4 {
  width: 240px;
}
.board-table .width-5 {
  width: 300px;
}
.board-table thead th {
  background-color: #B9B9DD;
  color: black;
  font-size: 16px;
  font-weight: bold;
  padding: 12px 0;
}

.br-10 {
  border-radius: 10px;
}

.br-50 {
  border-radius: 50px;
}

.btlr {
  border-top-left-radius: 10px;
}

.btrr {
  border-top-right-radius: 10px;
}

.box-shadow {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}

.form-select {
  border: 1px solid black;
  color: #B0B0E8;
}

.btn-border {
  border-radius: 10px;
}`],
})
export class PageManageMacroListComponent implements OnInit {

    public page = {
        start: 1,
        end: 1,
        current: 1,
    };

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, '/authenticate');
        await this.pageLoad(1);
        await this.service.render();
    }

    public async load() {
        let { code, data } = await wiz.call("load", { page: this.page.current });
        if (code == 200) {
            this.list = data.rows;
            this.lastpage = data.lastpage;
            this.page.start = (parseInt((this.page.current - 1) / 10) * 10) + 1;
            this.page.end = this.lastpage;
        }
        await this.service.render();
    }

    public async move(id) {
        if (!id) {
            await this.service.render();
            await this.service.href(`/macro/edit/new`);
        }
        else {
            await this.service.href(`/macro/edit/${id}`);
        }
    }

    public async build() {
        await this.loading(true);
        await wiz.call("build");
        await this.service.request.post("https://kmbig.seasonsoft.net/wiz/ide/api/workspace.app.explore/build");
        await this.loading(false);
        this.service.toast.success("Build Finish.");
    }

    private pageLoad(p: number) {
        this.page.current = p;
        this.load();
    }

    private async loading(act) {
        this._loading = act;
        await this.service.render();
    }
}

export default PageManageMacroListComponent;